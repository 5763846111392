import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import logo from "../../static/images/logo.svg"
import iconTwitter from "../../static/images/icon-twitter.svg"
import iconLinkedin from "../../static/images/icon-linkedin.svg"
import iconGitHub from "../../static/images/icon-github.svg"
import iconInstagram from "../../static/images/icon-instagram.svg"

const Footer = () => (
  <footer>
    <div className={"container"}>
      <div className={"row"}>
        <div className={"col-5"}>
          <div className={"widget__item"}>
            <div className={"logo"}>
              <Link to="/" title={"Tallyist"}>
                <img alt={"Logo"} src={logo} />
              </Link>
            </div>

            <div className={"about"}>
              <p>A simple todo list for your money.</p>
            </div>
          </div>
        </div>
      </div>

      <div className={"copyright"}>
        <p>
          Copyright {new Date().getFullYear()}, {` `}{" "}
          <a href="https://dausign.com" title={"dausign"}>
            dausign
          </a>
          . All rights reserved.
        </p>
      </div>
    </div>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
